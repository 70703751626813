<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('交易详情')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="det-card" v-if="info">
                <div class="det-robot">
                    <img class="ro-logo" src="../../assets/pictures/kefu2.png">
                    <div class="flex_bd">{{$t(info.name)}}</div>
                    <div class="det-info">
                        <div class="text">{{$t('收益')}}</div>
                        <div class="value">
                            {{ info.profit > 0 ? '+':'' }}
                            {{parseFloat(info.profit).toFixed(6)}}
                        </div>
                    </div>
                </div>
                <div class="det-items">
                    <div class="item">{{$t('机器人唯一码')}}：{{info.password}}</div>
                    <div class="item">{{$t('交易完成时间')}}：{{info.updated_at}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                id: 0,
                info: {},
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get( this.$api_url.order_info.replace(":id", this.id))
                    .then((res) => {
                        this.info = res.data.data;
                    });
            }
        },
        created() {
            if (this.$route.query.id != undefined) {
                this.id = this.$route.query.id;
            }
            this.getData();
        }

    }
</script>